<template>
  <div class="w-100">
    <div class="form-check mb-2" v-if="type === 'boolean'">
      <label class="form-check-label" :for="inputKey">
        {{ label }}
      </label>
      <toggle-button :id="inputKey" :value="value" v-on:change="$emit('input', $event.value)" />
    </div>
    <div class="form-group" v-else-if="type === 'integer'">
      <label>{{ label }}</label>
      <input type="number"
             v-bind:value="value"
             v-on:input="$emit('input', $event.target.value)"
             class="form-control" required>
    </div>
    <div class="form-group" v-else>
      <label>{{ label }}</label>
      <input type="text"
             v-bind:value="value"
             v-on:input="$emit('input', $event.target.value)"
             class="form-control" required>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputText',
  props: [
    'inputKey', 'value', 'type', 'label', 'label-right',
  ],
  model: {
    prop: 'value',
    event: 'input',
    checked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .form-check {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
</style>
