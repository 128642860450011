<template>
  <div class="row mt-6">
    <div class="col-sm groups-content">
      <div id="accordion-capacity-management">
        <div class="card">
          <div class="card-header" id="heading-capacity-management">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapse-capacity-management" aria-expanded="false"
                aria-controls="collapse-capacity-management">
              <feather type="arrow-down-circle" class="align-middle"></feather>
              <span class="align-middle"> Clinical Ops Management </span>
            </h5>
          </div>

          <div id="collapse-capacity-management" class="collapse" aria-labelledby="heading-capacity-management"
               data-parent="#accordion-capacity-management">
            <b-row class="ml-0 card-body">
              <b-form-group class="w-100" v-for="(levels, unit) in getCapacityLevels.levels" :key="unit">
              <span class="h4 mt-2 mb-2">
                {{ units[unit] }}
                <feather :id="`explanation-${unit}`" class="fa text-info" type="info"></feather>
                <b-tooltip :target="`explanation-${unit}`" placement="bottom">
                  {{ tooltips[unit] }}
                </b-tooltip>
              </span>

                <b-container v-for="(items, type) in levels" :key="type" class="mt-2 pl-0">
                  <b>{{ types[type] }}</b>
                  <b-row class="ml-0 mt-2">
                    <div v-for="(item, index) in items" :key="index" class="form-inline">
                      <span class="mr-2">Level {{ item.level }} </span>
                      <input :id="`${unit}-${item.level}`" type="number"
                             v-model="item.capacity"
                             class="form-control capacity-level mr-2">
                    </div>
                  </b-row>
                </b-container>
              </b-form-group>
              <div class="row mt-4">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <button @click="saveConfigs" type="button" :disabled="saving" class="btn btn-primary">
                    Save Levels
                  </button>
                </div>
              </div>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.capacity-level {
  max-width: 5rem;
}

.tooltip-inner {
  min-width: 100px;
  max-width: 100%;
}
</style>

<script>
import * as R from 'ramda';
import { mapGetters } from 'vuex';

export default {
  name: 'CapacityLevels',
  data: () => ({
    loading: false,
    saving: false,
    currentCapacityLevelsConfigs: {},
    units: {
      sword: 'Sword DPT',
      dpt_go: 'DPT GO',
      bloom: 'Bloom',
      move: 'Move',
      mind: 'Mind',
    },
    tooltips: {
      sword: 'Progression between levels 1 → 2 → 3 → 4 occurs every 2 weeks.',
      dpt_go: 'Progression between levels 1 → 2 → 3 → 4 occurs every 2 weeks.',
      bloom: 'Progression between levels 1 → 2 → 3 → 4 occurs every 2 weeks.',
      move: 'Progression between levels 1 → 2 → 3 → 4 occurs every 2 weeks.',
      mind: 'Progression between levels 1 → 2 → 3 → 4 occurs every 2 weeks.',
    },
    types: {
      enrollment: 'Last 7d Enrolment Cap - maximum # enrolments on the last 7 days',
      // eslint-disable-next-line max-len
      caseload: 'Caseload Capacity - maximum # of assigned Members (who are owned by PT) in Enrolled, Active, ActivePlus and On-Hold (700, 710 and 720)',
    },
  }),
  async mounted() {
    const that = this;
    this.loading = true;
    await this.$store.dispatch('Config/fetchCapacityLevelsConfigs').then(() => {
      that.currentCapacityLevelsConfigs = that.getCurrentCapacityLevelsConfigs();
      this.loading = false;
    });
  },
  methods: {
    ...mapGetters('Config', [ 'getCurrentCapacityLevelsConfigs' ]),
    saveConfigs() {
      this.saving = true;
      this.$store.dispatch('Config/saveCapacityLevelsConfigs').then(() => {
        this.saving = false;
      });
    },
  },
  computed: {
    getCapacityLevels() {
      const groupByUnitAndType = inputJson => {
        const levels = R.propOr([], 'levels', inputJson);

        const transformed = R.pipe(
          R.groupBy(R.prop('unit')),
          R.mapObjIndexed(
            R.pipe(
              R.groupBy(R.prop('type')),
              R.map(R.sortBy(R.prop('level'))),
              R.toPairs,
              R.sortWith([
                R.descend(R.prop(0)),
              ]),
              R.fromPairs,
            ),
          ),
        )(levels);

        return { levels: transformed };
      };

      return groupByUnitAndType(this.currentCapacityLevelsConfigs);
    },
  },
};
</script>
