<template>
  <div class="floating-btn">
    <button v-for="(button, key, index) in buttons" :key="key"
            :class="'btn btn-fab btn-' + button.color"
            :disabled="button.disabled"
            :title="button.title"
            href="javascript:void(0)"
            v-on:click="$emit('floating-button-click', key)"
            :id="'floating-btn-action-' + index">
      <feather :type="button.icon"></feather>
      {{button.text}}
    </button>
  </div>
</template>

<script>
export default {
  name: 'FloatingButtons',
  props: [
    'buttons',
  ],
};
</script>

<style>
  .floating-btn .btn-fab {
    position: fixed !important;
    right: 10px;
    border-radius: 15px;
  }

  #floating-btn-action-0 {
    bottom: 20px;
  }

  #floating-btn-action-1 {
    bottom: 65px
  }

  #floating-btn-action-2 {
    bottom: 110px
  }
</style>
